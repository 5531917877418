jQuery(window).load(function() {
  jQuery('#status').fadeOut();
  jQuery('#preloader').delay(350).fadeOut('slow');
  jQuery('body').delay(350).css({
    'overflow': 'visible'
  });
  jQuery('.carruselHome').carouFredSel({
    auto: false,
    responsive: true,
    prev: '#prev2',
    next: '#next2',
    width: '100%',
    scroll: 1,
    items: {
      width: 350,
      visible: {
        min: 2,
        max: 5
      }
    },
    swipe: {
      onMouse: true,
      onTouch: true
    }
  });
});

jQuery(document).ready(function() {

  jQuery('.homeIconsContainer a').each(function() {
     var a = new RegExp('/' + window.location.host + '/');
     if (!a.test(this.href)) {
        jQuery(this).attr("target","_blank");
     }
  });

  jQuery('.containerPrimeraInfancia .singleCloudLink').each(function(i){
    var num = (i%9) + 1;
    jQuery(this)
    .addClass('singleCloud0' + num );
  });

  var divs = jQuery('.singleCloudLink');
  for(var i = 0; i < divs.length; i+=9) {
    divs.slice(i, i+9).wrapAll('<div class="cloudsBlock"></div>');
  }

  jQuery('.carousel-indicators li:first-child').addClass('active');

  jQuery('p.attachment a').prepend('<i class="fa fa-download" aria-hidden="true"></i> Descargar ');

  jQuery('.close span').html('<span></span>');

  jQuery(".trail-items span:contains('Home')").html("Inicio");
  jQuery(".trail-browse").html("<span class='breadIcon'>Estás en :</span>&nbsp;");

  jQuery('.inputAccept .wpcf7-list-item-label').wrap('<a href="#lipsum"></a>');;

  jQuery('.homeIconsContainer div').each( function (index) {
    index += 1;
    if(index % 4 == 0) {
      jQuery(this).addClass('noRightMargin');
    }
   });

  jQuery('#menu-menu-footer li:odd').addClass('odd');
  jQuery('#menu-menu-footer li:even').addClass('even');

  jQuery('.singleSecondContent').each(function(i) {
      jQuery(this).addClass('singleSecondContentN0' + (i+1));
  });

  // PERFIL

  jQuery('#boton_guardar_mi_perfil').click(function(r){

    r.preventDefault();

    data = {
      'como_se_siente' : jQuery('#como_se_siente').val() ,
      'sobre_su_persona':jQuery('#sobre_mi').val() ,
      'id_usuario' : jQuery('#id_usuario').val(),
      'actualizando_perfil_local' : true
    }

    url= '/guardar-perfil-local';

    $.post(url,data,function(e){
      if(e.status){

        if( $.trim( jQuery('#archivo_avatar').val() ) != ''){
          jQuery('#form_avatar_usuario').submit();
        }

      } else {
        alert('Ocurrio un error al actulizar tus datos');
      }
    },'json');

  });

  jQuery('.fa-bars').click(function(){
      jQuery('.menuLateral').toggleClass('menuLateralActivo');
   });

  jQuery('.inputFormName').attr("placeholder", "Tu nombre");
  jQuery('.inputCity').attr("placeholder", "Santiago, Chile");
  jQuery('.inputFormEmail').attr("placeholder", "email@email.cl");
  jQuery('.inputFormMensaje').attr("placeholder", "Envíanos tu consulta...");


  jQuery('.supLinksMax').click(function(){
      curSize= parseInt(jQuery('.insideSingleContent, .pageMainContent, .singleDownload').css('font-size')) + 2;
  if(curSize<=20)
    jQuery('.insideSingleContent, .pageMainContent, .singleDownload').css('font-size', curSize);
  });
  jQuery('.supLinksMin').click(function(){
      curSize= parseInt(jQuery('.insideSingleContent, .pageMainContent, .singleDownload').css('font-size')) - 2;
  if(curSize>=12)
    jQuery('.insideSingleContent, .pageMainContent, .singleDownload').css('font-size', curSize);
  });

  jQuery('.destacadaDate span').each(function(){
    var recortarFechas = jQuery(this).html().split("",3);
    jQuery(this).html(recortarFechas);
  });
  jQuery('.dateActivBall span').each(function(){
    var recortarFechas = jQuery(this).html().split("",3);
    jQuery(this).html(recortarFechas);
  });

  jQuery('#accordion .panel-heading').each(function(i) {
      jQuery(this).attr('id', 'heading0' + (i+1));
  });
  jQuery('#accordion .panel-collapse').each(function(i) {
      jQuery(this).attr('id', 'collapse0' + (i+1));
  });
  jQuery('#accordion .panel-collapse').each(function(i) {
      jQuery(this).attr('aria-labelledby', 'heading0' + (i+1));
  });
  jQuery('#accordion .catchA').each(function(i) {
      jQuery(this).attr('href', '#collapse0' + (i+1));
  });
  jQuery('#accordion .catchA').each(function(i) {
      jQuery(this).attr('aria-controls', 'collapse0' + (i+1));
  });
  jQuery('#collapse01').addClass('in');
  jQuery('#heading01 a').attr('aria-expanded', 'true');
  jQuery('#heading01 a').removeClass('collapsed');

  jQuery('.list-pages-shortcode a').prepend('<i class="fa fa-angle-right"></i>');

  // jQuery('.carousel-indicators li:first-child').addClass('active');

  jQuery('.carousel-inner .item:first-child').addClass('active');

  jQuery('.gallery-item a').addClass('fancybox').attr('rel', 'fancyboxGaleria');

  jQuery('.fancybox').fancybox({
    padding: 0,
		openEffect	: 'elastic',
		closeEffect	: 'elastic'
	});

  var imageGalleryCount = 0;
  jQuery('.gallery figure').each(function(){
    if (imageGalleryCount >= 12) {
      jQuery(this).css('display','none');
      jQuery('.viewGaleria').css('display','block');
    }
    imageGalleryCount++;
  });

  var hrefGaleria = jQuery('.gallery figure:first-child a').attr('href');
  jQuery('.viewGaleria').attr('href', hrefGaleria);

});
